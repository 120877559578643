import stickElement from '../components/stickit'
import 'jquery-match-height'
import 'select2'
import 'slick-carousel'

const headerSearchInit = () => {
  const searchContainer = $('#search-container')
  $('#search-btn').on('click', () => {
    searchContainer.toggleClass('active')
  })

  $(document).mouseup(function(e) {
    if (
      !searchContainer.is(e.target) &&
      searchContainer.has(e.target).length === 0
    ) {
      searchContainer.removeClass('active')
    }
  })

  const mobileSearchContainer = $('#mobile-search-container')
  const mobileSearchButton = $('.js-mobile-search')
  mobileSearchButton.on('click', () => {
    mobileSearchContainer.slideToggle()
  })

  $(document).mouseup(function(e) {
    if (
      !mobileSearchContainer.is(e.target) &&
      mobileSearchContainer.has(e.target).length === 0 &&
      !mobileSearchButton.is(e.target) &&
      mobileSearchButton.has(e.target).length === 0
    ) {
      mobileSearchContainer.slideUp()
    }
  })
}

const megaMenuInit = () => {
  const toggleMenuItem = item => {
    item
      .toggleClass('show')
      .fadeToggle(300)
      .closest('.menu-item')
      .toggleClass('active')
      .closest('.menu-items__col')
      .toggleClass('active')
  }

  $('.js-megamenu > .dropdown').hover(
    function() {
      const megaMenuDropdown = $(this)

      megaMenuDropdown.toggleClass('open')
      // .children('.js-megamenu-dropdown')
      // .stop(false, true)
      // .slideToggle(600)

      // setTimeout(function() {
      //   megaMenuDropdown.find('.submenu-angle').addClass('active')
      // }, 350)
    },
    function() {
      $(this).toggleClass('open')
      // .children('.js-megamenu-dropdown')
      // .stop(false, true)
      // .slideToggle(200)
      // .find('.submenu-angle')
      // .removeClass('active')
    }
  )

  $('.js-megamenu .level-1.menu-item-has-children > .nav-link').on(
    'click',
    function(e) {
      e.preventDefault()
      const linkItem = $(this)
      const dropdownMenu = linkItem.siblings('.js-megamenu-dropdown')

      if (!dropdownMenu.hasClass('show')) {
        const openedDropwown = linkItem
          .closest('.js-megamenu-dropdown')
          .find('.js-megamenu-dropdown.show')
        toggleMenuItem(openedDropwown)
      }

      toggleMenuItem(dropdownMenu)
    }
  )

  $('.js-megamenu .submenu-close').on('click', function() {
    const dropdownMenu = $(this).closest('.js-megamenu-dropdown')

    toggleMenuItem(dropdownMenu)
  })
}

const mobileMenuInit = () => {
  accordionToggler(
    $('.js-main-navigation-toggler'),
    $('.mobile-navigation'),
    $('.banner')
  )

  $('.dropdown > .nav-link').each(function() {
    const item = $(this)
    const itemTitle = item.html()
    item
      .siblings('.mobile-dropdown')
      .children('.js-submenu-close')
      .html(itemTitle)
  })

  $('#mobile-navigation').on('click', '.dropdown > .nav-link', function() {
    $(this)
      .siblings('.mobile-dropdown')
      .fadeIn()
      .parents('.mobile-dropdown')
      .scrollTop(0)
      .addClass('fixed')
  })

  $('#mobile-navigation').on('click', '.js-submenu-close', function() {
    const targetDropdown = $(this).closest('.mobile-dropdown')

    targetDropdown.closest('.mobile-dropdown.fixed').removeClass('fixed')

    targetDropdown.fadeOut()
  })

  const viewport = window.bootstrapToolkit
  const navToggler = $('.js-main-navigation-toggler')
  $(window).resize(
    viewport.changed(function() {
      if (viewport.is('>md') && navToggler.hasClass('active')) {
        navToggler.trigger('click')
      }
    })
  )
}

const columnsSliderInit = () => {
  const viewport = window.bootstrapToolkit
  $('.js-vc_columns-slider').each(function() {
    const slider = $(this)
    const sliderArgs = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
    }
    sliderArgs['autoplay'] = $(this).hasClass('js-advantages-slider')
      ? true
      : false
    const sliderCheck = () => {
      if (viewport.is('<md')) {
        if (!slider.hasClass('slick-initialized')) {
          slider.slick(sliderArgs)
        }
      } else {
        if (slider.hasClass('slick-initialized')) {
          slider.slick('unslick')
        }
      }
    }

    sliderCheck()

    $(window).resize(
      viewport.changed(function() {
        sliderCheck()
      })
    )
  })
}

// Remove placeholder
const removePlaceholder = () => {
  $('input,textarea').each(function() {
    $(this).data('holder', $(this).attr('placeholder'))

    $(this).focusin(function() {
      $(this).attr('placeholder', '')
    })

    $(this).focusout(function() {
      $(this).attr('placeholder', $(this).data('holder'))
    })
  })
}

// Toggler for accordion, dropdown & spoiler
const accordionToggler = (
  $toggler,
  $handled = $toggler.data('target'),
  parent,
  slideSpeed = 300
) => {
  $toggler.each(function() {
    $(this).click(e => {
      const $parent = parent ? parent : 'div'

      $(e.currentTarget)
        .toggleClass('active')
        .closest($parent)
        .find($handled)
        .slideToggle(slideSpeed)
    })
  })
}

const slideToggle = el => {
  el.stop(true, true).slideToggle(600)
}

const teaserHover = () => {
  $(document).on('mouseenter', '.js-teaser', function(e) {
    if (
      !$(this)
        .find('.teaser-box__format')
        .is(e.target) &&
      $(this)
        .find('.teaser-box__format')
        .has(e.target).length === 0
    ) {
      const $teaser = $(this)
      const $teaserList = $('.js-teaser-list', $teaser)
      $teaser.addClass('hovered')
      if ($teaser.hasClass('js-animated')) {
        setTimeout(function() {
          slideToggle($teaserList)
        }, 250)
      } else {
        slideToggle($teaserList)
      }
      return false
    }
  })

  $(document).on('mouseleave', '.js-teaser', function(e) {
    if (
      !$(this)
        .find('.teaser-box__format')
        .is(e.target) &&
      $(this)
        .find('.teaser-box__format')
        .has(e.target).length === 0
    ) {
      const $teaser = $(this)
      const $teaserList = $('.js-teaser-list', $teaser)
      slideToggle($teaserList)
      if ($teaser.hasClass('js-animated')) {
        setTimeout(function() {
          $teaser.removeClass('hovered')
        }, 250)
      } else {
        $teaser.removeClass('hovered')
      }
      return false
    }
  })
  // }
}

// const teaserClick = () => {
//if ($(window).width() < 1025) {
// $('body').on('click', '.js-teaser', function() {
//   const $teaser = $(this)
//   const $teaserList = $(this).find('.js-teaser-list')
//
//   if ($teaser.hasClass('hovered')) {
//     $teaserList.slideUp()
//     $teaser.removeClass('hovered')
//   } else {
//     $teaserList.slideDown()
//     $teaser.addClass('hovered')
//   }
//   return false
// })
//}
// }

// Fit slide video background to video holder
const resizeVideo = () => {
  //var $video = $('.video');
  var $trailer = $('.videoHolder')
  $trailer.find('.video').each(function() {
    if ($trailer.width() / 16 > $trailer.height() / 9) {
      $(this).css({ width: '100%', height: 'auto' })
    } else {
      $(this).css({ width: 'auto', height: '100%' })
    }
  })
  $trailer.find('.responsive-embed').each(function() {
    if ($trailer.width() / 16 > $trailer.height() / 9) {
      $(this).css({ width: '100%', height: 'auto' })
    } else {
      $(this).css({ width: $trailer.height() * 16 / 9, height: '100%' })
    }
  })
}

function resizeFullWidthRow() {
  $('.vc_section, [data-vc-full-width="true"]').each(function() {
    // $('.vc_section').each(function() {
    $(this)
      .css({
        width: document.body.clientWidth,
        marginLeft: -document.body.clientWidth / 2,
      })
      .addClass('strached')
    if (!$(this).attr('data-vc-stretch-content')) {
      let padding =
        (document.body.clientWidth - $('.container.wrap').outerWidth()) / 2
      $(this).css({
        'padding-left': padding,
        'padding-right': padding,
      })
    }
  })
}

function animationAngle(angle) {
  angle.each(function() {
    const $currentAngle = $(this)
    const $angleAnchor = $currentAngle.next('.angle-anchor')
    const windowHeight = $(window).height()
    const offsetTop = $angleAnchor.offset().top
    const k = Math.round(3 / (windowHeight / 1.5) * 1000) / 1000
    const st = $(window).scrollTop()
    let rotateValue

    if ($currentAngle.hasClass('angle__negative')) {
      rotateValue = (st + windowHeight - offsetTop) * k
    } else if ($currentAngle.hasClass('angle__positive--bottom')) {
      rotateValue = 4 - (st + 130) * k
    } else {
      rotateValue = 4 - (st + windowHeight - offsetTop) * k
    }

    if (rotateValue <= 1) {
      rotateValue = 1.01
    } else if (rotateValue >= 4) {
      rotateValue = 4.01
    }

    if ($currentAngle.hasClass('angle__negative')) {
      rotateValue = -rotateValue
    }

    $currentAngle.css({
      '-webkit-transform': 'rotate(' + rotateValue + 'deg)',
      '-moz-transform': 'rotate(' + rotateValue + 'deg)',
      '-ms-transform': 'rotate(' + rotateValue + 'deg)',
      '-o-transform': 'rotate(' + rotateValue + 'deg)',
      transform: 'rotate(' + rotateValue + 'deg)',
      zoom: 1,
    })
  })
}

function animationAngleInit() {
  const $angle = $('.angle')
  animationAngle($angle)
  $(window).scroll(function() {
    animationAngle($angle)
  })
}

function gform_tag_switcher() {
  $('h2.gsection_title').each(function() {
    $(this).replaceWith('<p class="gsection_title">' + $(this).html() + '</p>')
  })
}

function vc_tta_tag_switcher() {
  $('span.vc_tta-title-text').each(function() {
    $(this).replaceWith(
      '<h3 class="vc_tta-title-text">' + $(this).html() + '</h3>'
    )
  })
}

function vc_tta_tag_switch_back() {
  $('.switch_tab_headline h3.vc_tta-title-text').each(function() {
    $(this).replaceWith(
      '<span class="vc_tta-title-text">' + $(this).html() + '</span>'
    )
  })
}

export default {
  init() {
    resizeFullWidthRow()

    megaMenuInit()

    mobileMenuInit()

    headerSearchInit()

    columnsSliderInit()

    animationAngleInit()

    resizeVideo()

    gform_tag_switcher()

    vc_tta_tag_switcher()

    vc_tta_tag_switch_back()

    $('.js-select2').select2({
      minimumResultsForSearch: Infinity,
    })

    // JavaScript to be fired on all pages
    removePlaceholder()

    $(document).bind('gform_post_render', function() {
      removePlaceholder()
    })

    accordionToggler($('.js-sitemap__link'), $('.site-map__menu'))

    accordionToggler(
      $('.js-readmore-toggler'),
      $('.js-readmore-text'),
      $('.call-to-action-box')
    )

    accordionToggler(
      $('.js-intro-section__menu--toggler'),
      $('.js-intro-section__menu--mobile'),
      $('.js-intro-section__menu')
    )

    teaserHover()
    // teaserClick()

    $(window).on('resize', function() {
      resizeFullWidthRow()

      resizeVideo()
      // text_spoiler()
    })
  },
  finalize() {
    stickElement('.js-sticky-banner')
    stickElement('.js-intro-section__menu')
  },
}
