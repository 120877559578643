import React from 'react'
import PropTypes from 'prop-types'
import { loadPosts } from '../../scripts/redux/modules/jobs'
import { loadStrings } from '../../scripts/redux/modules/textstrings'
import Spinner from '../../scripts/components/Spinner'

class Jobs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      postsShowed: 5,
      postsLoaded: 0,
      allPostsLoaded: false,
    }

    this.loadMoreJobs = this.loadMoreJobs.bind(this)
  }

  loadPosts(id, category, per_page, offset) {
    return this.props.dispatch(
      loadPosts(id, category, per_page, offset).bind(this)
    )
  }

  loadStrings() {
    return this.props.dispatch(loadStrings().bind(this))
  }

  loadMoreJobs() {
    const { postsShowed, postsLoaded, allPostsLoaded } = this.state
    const { id, category } = this.props

    if (!allPostsLoaded) {
      new Promise(resolve => {
        resolve(this.loadPosts(id, category, 5, postsLoaded))
      }).then(value => {
        if (value.length) {
          this.setState({
            postsLoaded: postsLoaded + value.length,
          })
        } else {
          this.setState({
            allPostsLoaded: true,
          })
        }
      })
    }

    this.setState({
      postsShowed: postsShowed + 5,
    })
  }

  componentDidMount() {
    const { id, category } = this.props
    const { postsLoaded, allPostsLoaded } = this.state

    if (!allPostsLoaded) {
      new Promise(resolve => {
        resolve(this.loadPosts(id, category, 10, false))
      }).then(value => {
        if (value.length) {
          this.setState({
            postsLoaded: postsLoaded + value.length,
          })
        }
      })
    }

    this.loadStrings()
  }

  render() {
    const { error, id, jobs, dataLoaded, strings, stringsLoaded } = this.props
    const { postsShowed, postsLoaded } = this.state

    if (error) {
      return <div>Error</div>
    }

    if (!dataLoaded && !stringsLoaded) {
      return <Spinner />
    }

    return (
      <div className="jobs__container">
        <ul className="jobs__list underlined-list color-fliedner_orange">
          {jobs[id] && jobs[id].length
            ? jobs[id].slice(0, postsShowed).map((item, i) => (
                <li key={i} className="jobs__item">
                  <a href={item.file} target="_blank" download>
                    <i className="la la-download" />
                    {item.title}
                  </a>
                  <p className="jobs__date">{item.date}</p>
                  <p className="color-petrol_a">{item.description}</p>
                </li>
              ))
            : null}
        </ul>
        <div className="row">
          <div className="col">
            {jobs[id] && jobs[id].length && postsShowed < postsLoaded ? (
              <button
                className="btn btn-primary"
                onClick={() => this.loadMoreJobs()}
              >
                {strings.moreposts ? strings.moreposts : 'Mehr beiträge'}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

Jobs.propTypes = {
  error: PropTypes.bool,
  dataLoaded: PropTypes.bool,
  dispatch: PropTypes.func,
  jobs: PropTypes.object,
  strings: PropTypes.object,
  stringsLoaded: PropTypes.bool,
  id: PropTypes.string,
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default Jobs
